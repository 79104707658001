


































































.profile-container {
  height: 100vh;
  background: url('../../assets/profile_bg.svg') no-repeat;
  background-size: contain;
  background-position-y: center;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}
